export const hotelList = [
    'Hotels near me',
    'Hotels in Manali',
    'Hotels in Nainital',
    'Hotels in Mount Abu',
    'Hotels in Agra',
    'Hotels in Haridwar',
    'Hotels in Gurgaon',
    'Hotels in Coimbatore',
    'Roomsstay Hotel UK',
    'Vacation Homes in Europe',
    'Hotels in Goa',
    'Hotels in Udaipur',
    'Hotels in Lonavala',
    'Hotels in Kodaikanal',
    'Hotels in Gangtok',
    'Hotels in Kolkata',
    'Hotels in Mandarmoni',
    'Hotels in Kasauli',
    'Roomsstay Hotel USA',
    'Homes in Scandinavia',
    'Hotels in Puri',
    'Hotels in Mussoorie',
    'Hotels in Munnar',
    'Hotels in Hyderabad',
    'Hotels in Coorg',
    'Hotels in Ahmedabad',
    'Hotels in Daman',
    'Hotels in Dehradun',
    'Roomsstay Hotel Mexico',
    'Homes in Southern Europe',
    'Hotels in Shimla',
    'Hotels in Mumbai',
    'Hotels in Darjeeling',
    'Hotels in Shirdi',
    'Hotels in Dalhousie',
    'Hotels in Varanasi',
    'Hotels in Madurai',
    'Coupons',
    'Roomsstay Hotel Indonesia',
    'Traum Holiday Homes',
];

export const amenitiesList = [
    { name: 'Pool', id: 'poolCheckbox' },
    { name: 'Fitness Center', id: 'fitnessCheckbox' },
    { name: 'Spa', id: 'spaCheckbox' },
    { name: 'Restaurant', id: 'restaurantCheckbox' },
    { name: 'Conference Room', id: 'conferenceRoomCheckbox' },
    { name: 'Wi-Fi Paid', id: 'wifiCheckbox' },
    { name: 'Parking', id: 'parkingCheckbox' },
    { name: 'Pet Friendly', id: 'petFriendlyCheckbox' },
    { name: 'Laundry Service', id: 'laundryCheckbox' },
    { name: 'Business Center', id: 'businessCenterCheckbox' },
    { name: 'Shuttle Service', id: 'shuttleCheckbox' },
    { name: '24-Hour Front Desk', id: 'frontDeskCheckbox' },
    { name: 'Gym', id: 'gymCheckbox' },
    { name: 'Lounge Area', id: 'loungeCheckbox' },
    { name: 'Free Wi-Fi', id: 'freeWifiCheckbox' },
    { name: 'TV', id: 'tvCheckbox' },
    { name: 'Air Conditioning', id: 'airConditioningCheckbox' },
    { name: 'Coffee Maker', id: 'coffeeMakerCheckbox' },
    { name: 'Balcony', id: 'balconyCheckbox' },
    { name: 'Jacuzzi', id: 'jacuzziCheckbox' },
    { name: 'Barbecue Area', id: 'barbecueCheckbox' },
    { name: 'Room Service', id: 'roomServiceCheckbox' },
    { name: 'Ensuite Bathroom', id: 'ensuiteBathroomCheckbox' },
    { name: 'Telephone', id: 'telephoneCheckbox' },
    { name: 'Daily Housekeeping', id: 'dailyHousekeepingCheckbox' },
    { name: 'Complimentary Toiletries', id: 'toiletriesCheckbox' },
    { name: 'Closet', id: 'closetCheckbox' },
    { name: 'Iron and Ironing Board', id: 'ironCheckbox' },
    { name: 'Hair Dryer', id: 'hairDryerCheckbox' },
    { name: 'Safe', id: 'safeCheckbox' },
    { name: 'Mini Fridge', id: 'miniFridgeCheckbox' },
    { name: 'Microwave', id: 'microwaveCheckbox' },
    { name: 'Desk', id: 'deskCheckbox' },
    { name: 'Wake-up Service', id: 'wakeUpServiceCheckbox' },
    { name: 'Heating', id: 'heatingCheckbox' },
    { name: 'Cable Channels', id: 'cableChannelsCheckbox' },
    { name: 'Non-Smoking Rooms', id: 'nonSmokingCheckbox' },
    { name: 'Soundproof Rooms', id: 'soundproofCheckbox' },
    { name: 'Family Rooms', id: 'familyRoomsCheckbox' },
    { name: 'Elevator', id: 'elevatorCheckbox' },
    { name: 'Wheelchair Accessible', id: 'wheelchairAccessibleCheckbox' },
    { name: 'Airport Shuttle', id: 'airportShuttleCheckbox' },
    { name: 'Concierge Service', id: 'conciergeCheckbox' },
    { name: 'Valet Parking', id: 'valetParkingCheckbox' },
    { name: 'Currency Exchange', id: 'currencyExchangeCheckbox' },
    { name: 'ATM on Site', id: 'atmCheckbox' },
    { name: 'Gift Shop', id: 'giftShopCheckbox' },
    { name: 'Express Check-in Check-out', id: 'expressCheckInCheckbox' },
    { name: 'Tour Desk', id: 'tourDeskCheckbox' },
    { name: 'Ticket Service', id: 'ticketServiceCheckbox' },
    { name: 'Luggage Storage', id: 'luggageStorageCheckbox' },
    { name: 'Library', id: 'libraryCheckbox' },
    { name: 'Sun Terrace', id: 'sunTerraceCheckbox' },
    { name: 'Garden', id: 'gardenCheckbox' },
    { name: 'Picnic Area', id: 'picnicAreaCheckbox' },
    { name: 'Outdoor Furniture', id: 'outdoorFurnitureCheckbox' },
    { name: 'Terrace', id: 'terraceCheckbox' },
    { name: 'BBQ Facilities', id: 'bbqFacilitiesCheckbox' },
    { name: 'Vending Machine Drinks', id: 'vendingMachineDrinksCheckbox' },
    { name: 'Vending Machine Snacks', id: 'vendingMachineSnacksCheckbox' },
    { name: 'Special Diet Menus on request', id: 'specialDietMenusCheckbox' },
    { name: 'Packed Lunches', id: 'packedLunchesCheckbox' },

    { name: 'Bar', id: 'barCheckbox' },
    { name: 'Wine Champagne', id: 'wineChampagneCheckbox' },
    { name: 'Bottle of Water', id: 'bottleOfWaterCheckbox' },
    { name: 'Chocolate Cookies', id: 'chocolateCookiesCheckbox' },
    { name: 'Kid-Friendly Buffet', id: 'kidFriendlyBuffetCheckbox' },
    { name: 'Kid Meals', id: 'kidMealsCheckbox' },
    { name: 'Breakfast in the Room', id: 'breakfastInRoomCheckbox' },
    { name: 'Restaurant Buffet', id: 'restaurantBuffetCheckbox' },
    { name: 'Snack Bar', id: 'snackBarCheckbox' },

    { name: 'Fruit', id: 'fruitCheckbox' },
    { name: 'Buffet Breakfast', id: 'buffetBreakfastCheckbox' },
    { name: 'Continental Breakfast', id: 'continentalBreakfastCheckbox' },
    { name: 'Gluten-Free Options', id: 'glutenFreeOptionsCheckbox' },
    { name: 'Vegetarian Options', id: 'vegetarianOptionsCheckbox' },
    { name: 'Vegan Options', id: 'veganOptionsCheckbox' },
    { name: 'Halal Options', id: 'halalOptionsCheckbox' },
    { name: 'Kosher Options', id: 'kosherOptionsCheckbox' },
    { name: 'Allergy-Free Room', id: 'allergyFreeRoomCheckbox' },
    { name: 'Designated Smoking Area', id: 'smokingAreaCheckbox' },
    { name: 'Non-Smoking Throughout', id: 'nonSmokingThroughoutCheckbox' },
    { name: 'Kitchen', id: 'kitchen' },
];

// Define filter options
import {
    FaWineGlass,
    FaUtensils,
    FaPhone,
    FaCoffee,
    FaDoorOpen,
    FaHotTub,
    FaTv,
    FaWifi,
    FaDumbbell,
    FaShuttleVan,
    FaSwimmingPool,
    FaSpa,
    FaChalkboardTeacher,
    FaParking,
    FaDog,
    FaTshirt,
    FaBriefcase,
    FaCouch,
    FaSnowflake,
    FaFireAlt,
    FaShower,
    FaPhoneAlt,
    FaBroom,
    FaSoap,
    FaSuitcase,
    FaLock,
    FaClock,
    FaThermometer,
    FaSmokingBan,
    FaVolumeMute,
    FaBed,
    FaWheelchair,
    FaPlaneDeparture,
    FaUserFriends,
    FaCar,
    FaMoneyBillAlt,
    FaGift,
    FaClipboardCheck,
    FaMapMarkedAlt,
    FaTicketAlt,
    FaBookOpen,
    FaSun,
    FaLeaf,
    FaTree,
    FaChair,
    FaCity,
    FaCocktail,
    FaCandyCane,
    FaAppleAlt,
    FaHamburger,
    FaCookieBite,
    FaChild,
    FaBan,
    FaCarrot,
    FaMosque,
    FaStarOfDavid,
    FaAllergies,
    FaSmoking,
} from 'react-icons/fa';
import { BiSolidDryer } from 'react-icons/bi';
import { LuRefrigerator } from 'react-icons/lu';
import { FaElevator } from 'react-icons/fa6';
import { GiDesk } from 'react-icons/gi';
import { MdMicrowave } from 'react-icons/md';

export const roomTypes = ['Single Room', 'Double Room', 'Suite', 'Deluxe Room', 'Executive Room', 'Family Room', 'Penthouse', 'Studio'];

export const propertyTypes = [
    'Hotel',
    'Motel',
    'Hostel',
    'Guesthouse',
    'Bed and Breakfast',
    'Apartment',
    'Resort',
    'Villa',
    'Inn',
    'Chalet',
];

export const bedTypes = [
    'King Size Bed',
    'Queen Size Bed',
    'Double Bed',
    'Single Bed',
    'Twin Beds',
    'Sofa Bed',
    'Rollaway Bed',
    'Bunk Beds',
    'Extra Large Bed',
];

export const starRatings = ['1', '2', '3', '4', '5'];

const amenityIcons = {
    'Continental Breakfast': <FaUtensils />,
    'Room Service': <FaPhone />,
    'Coffee Maker': <FaCoffee />,
    Balcony: <FaDoorOpen />,
    Jacuzzi: <FaHotTub />,
    TV: <FaTv />,
    'Free Wi-Fi': <FaWifi />,
    Gym: <FaDumbbell />,
    '24-Hour Front Desk': <FaShuttleVan />,
    'Shuttle Service': <FaShuttleVan />,
    Pool: <FaSwimmingPool />,
    'Fitness Center': <FaDumbbell />,
    Spa: <FaSpa />,
    Restaurant: <FaUtensils />,
    'Conference Room': <FaChalkboardTeacher />,
    'Wi-Fi(Paid)': <FaWifi />,
    Parking: <FaParking />,
    'Pet Friendly': <FaDog />,
    'Laundry Service': <FaTshirt />,
    'Business Center': <FaBriefcase />,
    'Lounge Area': <FaCouch />,
    'Air Conditioning': <FaSnowflake />,
    'Barbecue Area': <FaFireAlt />,
    'Ensuite Bathroom': <FaShower />,
    Telephone: <FaPhoneAlt />,
    'Daily Housekeeping': <FaBroom />,
    'Complimentary Toiletries': <FaSoap />,
    Closet: <FaSuitcase />,
    'Iron and Ironing Board': <FaTshirt />,
    'Hair Dryer': <BiSolidDryer />,
    Safe: <FaLock />,
    'Mini Fridge': <LuRefrigerator />,
    Microwave: <MdMicrowave />,
    Desk: <GiDesk />,
    'Wake-up Service': <FaClock />,
    Heating: <FaThermometer />,
    'Cable Channels': <FaTv />,
    'Non-Smoking Rooms': <FaSmokingBan />,
    'Soundproof Rooms': <FaVolumeMute />,
    'Family Rooms': <FaBed />,
    Elevator: <FaElevator />,
    'Wheelchair Accessible': <FaWheelchair />,
    'Airport Shuttle': <FaPlaneDeparture />,
    'Concierge Service': <FaUserFriends />,
    'Valet Parking': <FaCar />,
    'Currency Exchange': <FaMoneyBillAlt />,
    'ATM on Site': <FaMoneyBillAlt />,
    'Gift Shop': <FaGift />,
    'Express Check-in/Check-out': <FaClipboardCheck />,
    'Tour Desk': <FaMapMarkedAlt />,
    'Ticket Service': <FaTicketAlt />,
    'Luggage Storage': <FaSuitcase />,
    Library: <FaBookOpen />,
    'Sun Terrace': <FaSun />,
    Garden: <FaLeaf />,
    'Picnic Area': <FaTree />,
    'Outdoor Furniture': <FaChair />,
    Terrace: <FaCity />,
    'BBQ Facilities': <FaFireAlt />,
    'Vending Machine (Drinks)': <FaCocktail />,
    'Vending Machine (Snacks)': <FaCandyCane />,
    'Special Diet Menus (on request)': <FaAppleAlt />,
    'Packed Lunches': <FaHamburger />,
    Bar: <FaCocktail />,
    'Wine/Champagne': <FaWineGlass />,
    'Bottle of Water': <FaCocktail />,
    'Chocolate/Cookies': <FaCookieBite />,
    'Kid-Friendly Buffet': <FaChild />,
    'Kid Meals': <FaChild />,
    'Breakfast in the Room': <FaUtensils />,
    'Restaurant Buffet': <FaUtensils />,
    'Snack Bar': <FaUtensils />,
    Fruit: <FaAppleAlt />,
    'Buffet Breakfast': <FaUtensils />,
    'Gluten-Free Options': <FaBan />,
    'Vegetarian Options': <FaCarrot />,
    'Vegan Options': <FaLeaf />,
    'Halal Options': <FaMosque />,
    'Kosher Options': <FaStarOfDavid />,
    'Allergy-Free Room': <FaAllergies />,
    'Designated Smoking Area': <FaSmoking />,
    'Non-Smoking Throughout': <FaSmokingBan />,
    Kitchen: <FaUtensils />,
};
export default amenityIcons;

export const termsArray = [
    'Guests must present a valid ID upon check-in.',
    'Pets are not allowed on the premises.',
    'Cancellations must be made 24 hours in advance.',
    'Late check-out is subject to availability and may incur additional charges.',
    'Management reserves the right to refuse service.',
];
